import {  Stack,   } from "@suid/material";
import { CardWithMinimize } from '../AppMenuBar/CardList'
import { NotificationListItem } from '../SmallComponents/SmallNotificationList'
import { MatchingIcon, JobIcon,ContractIcon, ProposalIcon, InterviewIcon } from '../icons'
import { getText } from '../SmallComponents/Translate'

const CustomerToDosComponent = () => {
    const txtHeader = getText("todopage", "header")
    const txtAll = getText("default", "all")
    const txtReadyToSign = getText("todopage", "numbersreadytosign", [{ name: "Number", value: "3" }])
    const txtHaveProposal = getText("todopage", "numbershaveproposal", [{ name: "Number", value: "2" }])
    const txtUpcommingInterviews = getText("todopage", "numbersupcomminginterviews", [{ name: "Number", value: "3" }])
    const txtMacthingWip = getText("todopage", "numbersmatchingwip", [{ name: "Number", value: "1" }])
    const txtMatchingNotStarted = getText("todopage", "numbersmatchingsnotstarted", [{ name: "Number", value: "3" }])

    return (
        <>
            {/* List of all users to-dos accross all groups*/}
            <Stack >
                <CardWithMinimize header={txtHeader() as string} type="list" defaultOpen={true}>
                    <NotificationListItem route="/customertodo" secondary="Ida, Billy, Edith" notificationIcon={<ContractIcon />} >{txtReadyToSign()}</NotificationListItem>
                    <NotificationListItem route="/customertodo" secondary="Nick, Andy" notificationIcon={<ProposalIcon />} >{txtHaveProposal()}</NotificationListItem>
                    <NotificationListItem route="/customertodo" secondary="Ida 12/4 13:00, Bo 12/4 16:00" notificationIcon={<InterviewIcon />} >{txtUpcommingInterviews()} </NotificationListItem>
                    <NotificationListItem route="/customertodo" secondary="Berta" notificationIcon={<JobIcon />} >{txtMacthingWip()}</NotificationListItem>
                    <NotificationListItem route="/customertodo" secondary="Project Manager, HR Lead, HR Experet" notificationIcon={<MatchingIcon />} showDivider={false} >{txtMatchingNotStarted()}</NotificationListItem>
                </CardWithMinimize>
            </Stack>
        </>
    );
}
export { CustomerToDosComponent }